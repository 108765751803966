import React from "react";
import CheckCircle from "../assests/icons/CheckCircle.svg";
import Vector from "../assests/icons/Vector.svg";
// import VectorWhite from "../assests/icons/Vector-white.svg";
// import ArrowRightBlack from "../assests/icons/ArrowRightBlack.svg";
import Lighting from "../assests/icons/Lightning.svg";
import LightingGray from "../assests/icons/Lightning-grey.svg";
// import EmailSection from "./EmailSection";
// import { Dialog } from "@headlessui/react";
import Gauge from "../assests/icons/Gauge.svg";

const Card = ({
  title,
  interestedCount,
  planDuration,
  details,
  difficultyLevel,
  dialogText,
  profession,
  selectedAge,
  onCardSelect,
  selectedCards,
  id,
  score,
  professionalSummary,
}) => {
  // const [isOpen, setIsOpen] = useState(false);

  // const toggleCuratingPlan = () => {
  //   setIsOpen(true);
  // };

  const selected = selectedCards?.includes(id)
    ? "border-gradient-2"
    : "border-[#302d38]";

  return (
    <div className="card-plan" onClick={onCardSelect}>
                    <div className="text-white text-sm font-normal font-['Outfit']">
                  {professionalSummary}
              </div>
      <div
        className={`cursor-pointer w-full h-full grow shrink basis-0 px-6 py-[30px] bg-[#3f3c45]/30 rounded-[40px] shadow border backdrop-blur-[56px] flex-col justify-start items-start gap-6 inline-flex ${selected}`}
      >
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch justify-start items-center gap-4 inline-flex card-name">
            <div className="grow shrink basis-0 justify-start items-center gap-2 flex">
              <div className="w-6 h-6 relative">
                <img
                  src={Vector}
                  alt="Check Circle Icon"
                  className="w-full h-full"
                />
              </div>
              <h4 className="text-[#4fd049] text-xl font-medium font-['Outfit'] text-gradient-2">
                {title}
              </h4>
            </div>
            <div className="h-8 px-4 py-2 rounded-[999px] border border-[#302d38] justify-center items-center gap-2 inline-flex w-full sm:w-auto">
              <div className="text-[#aeadb2] text-sm font-medium font-['Outfit'] flex gap-3">
                <div className="w-5 h-5 relative">
                  <img
                    src={Gauge}
                    alt="Gauage Icon"
                    className="w-full h-full"
                  />
                </div>
                {score}
              </div>
            </div>
          </div>
          <div className="self-stretch justify-between items-start inline-flex card-button-container-main">
            <div className="justify-start items-center gap-2 flex card-button-container">
              {/* <div className="px-5 py-2 bg-gradient-to-r from-[#4fd049] to-[#cbc435] rounded-[999px] justify-center items-center gap-3 flex card-button">
                <div className="text-[#06030b] text-sm font-medium font-['Outfit']">{planDuration} Plan</div>
              </div> */}
              <div className="px-5 py-2 rounded-[999px] border border-[#4fd049] justify-center items-center gap-3 flex card-button">
                <div className="text-white text-sm font-normal font-['Outfit']">
                  {planDuration}
                </div>
              </div>
            </div>
            <div className="justify-start items-center gap-2 flex difficulty-level">
              <div className="text-[#aeadb2] text-xs font-light font-['Outfit']">
                {difficultyLevel}
              </div>
              <div className="p-1.5 bg-[#3f3c45]/30 rounded-[99px] justify-start items-center gap-2 flex difficulty-level">
                {Array.from({ length: 3 }).map((_, index) => {
                  const isLighting =
                    (difficultyLevel === "Easy" && index === 0) ||
                    (difficultyLevel === "Moderate" && index < 2) ||
                    (difficultyLevel === "Difficult" && index < 2) ||
                    difficultyLevel === "Challenging";

                  return (
                    <div key={index} className="w-5 h-5 relative">
                      <img
                        src={isLighting ? Lighting : LightingGray}
                        alt={
                          isLighting ? "Lighting Icon" : "Lighting Gray Icon"
                        }
                        className="w-full h-full"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-2 flex">
          <h6 className="text-white text-sm font-medium font-['Outfit']">
            Plan Details
          </h6>
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 justify-start item-start sm:items-center gap-2 flex">
              <div className="w-6 h-6">
                <img
                  src={CheckCircle}
                  alt="Check Circle Icon"
                  className="w-full h-full"
                />
              </div>
              <p className="text-[#aeadb2] text-[15px] font-normal font-['Outfit'] w-[80%] sm:w-full">
                {details}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="self-stretch justify-start items-start gap-6 inline-flex">
          <button
            className="grow shrink basis-0 h-12 px-5 py-2 bg-gradient-to-r from-[#4fd049] to-[#cbc435] rounded-[999px] justify-center items-center gap-3 flex aiPlansScreen"
            onClick={toggleCuratingPlan}
          >
            <button className="flex items-center gap-2 px-4 py-2 text-[#06030b] text-sm font-normal font-['Outfit'] rounded-md">
              <div className="w-4 h-4 relative">
                <img
                  src={VectorWhite}
                  alt="Vector White Icon"
                  className="w-full h-full"
                />
              </div>
              <span>Select Plan</span>
              <div className="w-4 h-4 relative">
                <img
                  src={ArrowRightBlack}
                  alt="Arrow Right Black Icon"
                  className="w-full h-full"
                />
              </div>
            </button>
          </button>

          <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            className="relative z-50"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center h-full">
              <Dialog.Panel className="bg-custom-gray w-full h-full overflow-auto shadow-lg relative">
                <button
                  onClick={() => setIsOpen(false)}
                  className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                >
                  ✕
                </button>

                <EmailSection
                  selectedAge={selectedAge}
                  profession={profession}
                  dialogText={dialogText}
                />
              </Dialog.Panel>
            </div>
          </Dialog>
        </div> */}
      </div>
    </div>
  );
};

export default Card;
