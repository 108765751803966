import React from "react";
import FAQ from "../components/FAQ";
import SEO from "../components/Seo";

const FAQPage = () => {
  return (
    <div className="FaqPage">
      <SEO
        title="trackhabit.io - FAQ"
        description="Read the Frequently Asked Questions to learn how to make the best of using TrackHabit App. "
        canonical="https://trackhabit.io/"
      />
      <div className="sm:px-16 pt-[82px]">
        <FAQ />
      </div>
    </div>
  );
};
export default FAQPage;
