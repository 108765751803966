import React from "react";
import SEO from "../components/Seo";

const TermsAndConditions = () => {
  return (
    <div className="max-w-[1230px] mx-auto pt-[65px] sm:pt-[40px] pb-[96px] px-[32px]">
      <SEO
        title="trackhabit.io - Terms of Service"
        description="Read the TOS policies for TrackHabit, your habit tracker application. Make sure to understand what using the service means for you."
        canonical="https://trackhabit.io/"
      />
      <div className="flex-col justify-center items-center gap-8 inline-flex">
        <div className="self-stretch flex-col justify-start items-start gap-6 flex border-b border-[#65636A] pb-[32px]">
          <div className="self-stretch flex-col justify-start items-start gap-3 flex ">
            <h1 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
              Terms and Conditions
            </h1>
            <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
              Last updated: 3 December 2024
            </p>
          </div>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            Welcome to TrackHabit.io! These Terms and Conditions ("Terms")
            govern your access to and use of our website and services. By
            accessing or using TrackHabit.io, you confirm that you have read,
            understood, and agreed to these Terms. If you disagree with any part
            of these Terms, please refrain from using our website or services.
          </p>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            1. Use of Our Services
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            TrackHabit.io is a platform designed to help users track habits and
            improve productivity. By accessing or using our services, you agree
            to:
          </p>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 pt-[12px]">
              <li>
                {" "}
                Use the platform solely for personal, non-commercial purposes
                unless expressly authorized.
              </li>
              <li>
                Not use our services in any way that is unlawful, fraudulent, or
                harmful to TrackHabit, its users, or third parties.
              </li>
              <li>
                Avoid uploading or transmitting any harmful content, such as
                viruses, malware, or any content designed to interfere with the
                functionality of the website or services.
              </li>
              <li>
                Respect the rights of other users and refrain from engaging in
                harassment, abusive behavior, or violations of privacy.
              </li>
              <li>
                Refrain from reverse engineering, decompiling, or attempting to
                extract the source code of our software or services.
              </li>
            </ul>
          </p>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            2. User Accounts
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            To access certain features of TrackHabit.io, you may be required to
            create an account. By creating an account, you agree to the
            following:
          </p>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 pt-[12px]">
              <li>
                Provide accurate, complete, and up-to-date information during
                registration and promptly update your information as necessary.
              </li>
              <li>
                Maintain the confidentiality of your account credentials and not
                share your login details with others.
              </li>
              <li>
                Be solely responsible for any activities that occur under your
                account, whether authorized by you or not.
              </li>
              <li>
                Notify us immediately if you suspect unauthorized access to your
                account or any security breach.
              </li>
            </ul>
          </div>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            Failure to comply with these requirements may result in suspension
            or termination of your account.
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            3. Intellectual Property
          </h2>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 ">
              <li>
                No Warranty: TrackHabit.io is provided "as is" without
                warranties of any kind, whether express or implied. We do not
                guarantee that our services will be error-free, secure, or
                uninterrupted.
              </li>
              <li>
                Liability Cap: To the fullest extent permitted by law, our
                liability for any claims arising from your use of TrackHabit.io
                shall not exceed the total fees paid by you to us in the
                preceding 12 months.
              </li>
              <li>
                Exclusions: We are not liable for any indirect, incidental,
                punitive, or consequential damages, including loss of data,
                profits, or business opportunities, resulting from your use of
                our platform.
              </li>
            </ul>
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            4. Limitation of Liability
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            We do not sell, rent, or share your personal information with third
            parties, except:
          </p>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 ">
              <li>When required by law or to comply with legal processes. </li>
              <li>
                With trusted service providers who assist in our operations,
                under strict confidentiality agreements.
              </li>
            </ul>
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            5. Termination
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            We reserve the right to suspend or terminate your account or access
            to TrackHabit.io at our discretion, including but not limited to:
          </p>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 pt-[12px]">
              <li> Violations of these Terms or applicable laws.</li>
              <li> Suspected fraudulent, abusive, or harmful activities.</li>
              <li> Non-payment of any applicable fees or charges.</li>
            </ul>
          </div>

          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            Termination may occur with or without prior notice. Upon
            termination, your right to access TrackHabit.io will cease
            immediately, and any data associated with your account may be
            deleted or retained as required by law.
          </p>
        </div>
        <div className="self-stretch  flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            6. Modifications to Terms
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            We may update these Terms periodically to reflect changes in our
            practices or for other operational, legal, or regulatory reasons.
          </p>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 pt-[12px]">
              <li>
                {" "}
                Notification: Significant changes will be communicated through
                notices on our website or via email.
              </li>
              <li>
                {" "}
                User Responsibility: You are encouraged to review these Terms
                regularly. Continued use of our services after changes are
                implemented signifies your acceptance of the revised Terms.
              </li>
            </ul>
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            7. Governing Law and Dispute Resolution
          </h2>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 pt-[12px]">
              <li>
                {" "}
                Applicable Law: These Terms are governed by the laws of [Your
                Jurisdiction], excluding conflict of law principles.
              </li>

              <li>
                {" "}
                Dispute Resolution: Any disputes arising under or in connection
                with these Terms shall be resolved exclusively in the courts of
                [Your Jurisdiction]. You agree to waive any objection to the
                venue or jurisdiction of such courts.
              </li>
            </ul>
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            8. Third-Party Services and Links
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            TrackHabit.io may include links to third-party websites or services.
            We are not responsible for the practices or content of these
            external sites.
          </p>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 pt-[12px]">
              <li>
                {" "}
                User Responsibility: Accessing third-party websites is at your
                own risk, and you should review their terms and policies.
              </li>
              <li>
                {" "}
                No Endorsement: The inclusion of third-party links does not
                imply endorsement or affiliation with such services.
              </li>
            </ul>
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-3 flex ">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            9. Contact Us
          </h2>
          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            For questions or concerns about this Privacy Policy, please contact
            us at:  <br />
            Email: support@trackhabit.io 
          </div>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            We value your input and strive to address your concerns promptly and
            efficiently.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
