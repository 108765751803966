import React from "react";

const InfoCard2 = ({ head, text, image }) => {
  return (
    <div className="info-card2-text-img bg-custom-gray">
      <div className="w-full bg-custom-gray justify-center items-center gap-[72px] inline-flex">
        <div className="w-full max-w-[1076px] info-card-inner justify-center items-center gap-[72px] flex flex-col px-8 sm:px-16 pt-8 pb-24 lg:flex-row lg:px-4 lg:pt-4 lg:pb-6">
          {/* Image first */}
          <div className="">
            <div className="">
              <div className="">
                <img
                  src={image}
                  alt="Dynamic Content"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
          {/* Text after image */}
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
            <h3 className="self-stretch text-white text-[2rem] font-normal font-['Outfit']">
              {head}
            </h3>
            <p className="self-stretch text-[#aeadb2] text-[1.25rem] font-normal font-['Outfit']">
              {text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoCard2;
