import React, { useState } from "react";
import HomeComponet from "../components/homecomponent";
import AnimatedText from "../components/AnimatedText";
import Vector from "../assests/icons/Vector.svg";
import CuratingPlanMentor from "../components/CuratingPlanMentor";
import { Dialog } from "@headlessui/react";
import SEO from '../components/Seo';
import Hero from '../assests/images/Hero.jpg'


const Mentor = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [professionMentor, setProfessionMentor] = useState("");
  const [dialogProfession, setDialogProfession] = useState("");
  const [dialogText, setDialogText] = useState("");

  const toggleCuratingPlan = () => {
    setDialogText(inputPrompt);
    setDialogProfession(professionMentor);
    setIsOpen(true);
  };

  const handleSubmission = () => {
    toggleCuratingPlan();
    console.log("Submitted:", inputPrompt);
    // Add any additional logic like API calls here.
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      inputPrompt.trim() !== "" &&
      professionMentor.trim() !== ""
    ) {
      handleSubmission();
    }
  };

  return (
    <div className="mentor-page">
      <SEO 
        title="trackhabit.io - follow your mentor routine"
        description="Easily track your habits to achieve your goals. Transform your life by following a routine of successful people! Follow their activities, update your lifestyle, and achieve your goals with our AI-driven platform. Try it for free!"
        canonical="https://trackhabit.io/"
      />
      <div className="hero-section relative overflow-hidden">
        <div className="w-full px-8 sm:px-16 pt-[120px] pb-[4.5rem] from-[#06030b] via-[#06030b] to-[#06030b] flex-col justify-start items-center gap-[72px] inline-flex relative z-10 hero-container bg-custom-gray">
        <img className="absolute top-0 left-0 w-full h-full object-cover" src={Hero} alt="hero"/>
          <div className="self-stretch flex-col justify-start items-center gap-8 flex z-[99]">
          <h1 className="self-stretch flex-col justify-center items-center flex">
              <div className="self-stretch text-center text-white font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px]">
                Discover the
              </div>
              <div className="inline-flex flex-col gap-2 sm:flex-row sm:gap-4 md:flex-row md:gap-4 self-stretch justify-center items-center animated-text-container">
                <div className="text-[#302d38] font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px] text-center sm:text-left sm:justify-start sm:items-start text-era text-gradient">
                  New Era of
                </div>
                <div className="flex-col justify-start items-center inline-flex animated-text">
                  <div className="w-full justify-start items-center inline-flex">
                    <div className="flex-col justify-center items-start inline-flex ">
                      <AnimatedText />
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch text-center text-white font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px]">
                With Powerful AI
              </div>
            </h1>
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <p className="grow shrink basis-0 text-center text-[#aeadb2] text-2xl font-light font-['Outfit'] max-w-[600px]">
                Gain personalized recommendations and real-time analytics to
                optimize your daily habits.
              </p>
            </div>
          </div>

          <div className="w-full max-w-[1076px] mx-auto self-stretch px-6 pt-4 pb-8 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-6 flex sm:px-8">
            <div className="self-stretch text-[#aeadb2] text-lg font-normal font-['Outfit']">
              Let us know who do you want to be like
            </div>

            {/* "I want to be like" Section */}
            <div className="self-stretch justify-start items-start sm:items-center gap-6 flex flex-col sm:flex-row">
              <div className="grow shrink basis-0 text-white text-lg font-normal font-['Outfit']">
                I want to be like
              </div>
              <div className="w-full sm:max-w-[82%] pl-6 pr-2 py-4 bg-[#3f3c45]/30 rounded-[999px] border-2 border-[#302d38] flex justify-start items-center">
                <input
                  type="text"
                  placeholder="e.g. Tiger Woods"
                  onChange={(e) => setInputPrompt(e.target.value)}
                  onKeyDown={handleKeyDown} // Listen for "Enter" key
                  required
                  className="text-[#65636a] text-lg font-medium font-['Outfit'] bg-transparent outline-none border-none placeholder-[#65636a] w-full"
                />
              </div>
            </div>

            {/* "Their Profession" Section */}
            <div className="self-stretch justify-start items-start sm:items-center gap-6 flex flex-col sm:flex-row">
              <div className="grow shrink basis-0 text-white text-lg font-normal font-['Outfit']">
                Their Profession is
              </div>
              <div className="w-full sm:max-w-[82%] pl-6 pr-2 py-4 bg-[#3f3c45]/30 rounded-[999px] border-2 border-[#302d38] flex justify-start items-center">
                <input
                  type="text"
                  placeholder="e.g. Golf"
                  onChange={(e) => setProfessionMentor(e.target.value)}
                  onKeyDown={handleKeyDown} // Listen for "Enter" key
                  required
                  className="text-[#65636a] text-lg font-medium font-['Outfit'] bg-transparent outline-none border-none placeholder-[#65636a] w-full"
                />
              </div>
            </div>

            {/* Generate Button and Dialog */}
            <div className="self-stretch flex-col justify-start items-end gap-2 flex">
              <button
                onClick={() => {
                  if (
                    inputPrompt.trim() === "" ||
                    professionMentor.trim() === ""
                  ) {
                    alert("Please fill out both fields.");
                  } else {
                    handleSubmission();
                  }
                }}
                className="flex px-5 py-2 rounded-full border border-gradient-generate text-white text-sm font-['Outfit'] gap-2 goalSubmitted border border-[#50D049] w-full sm:w-auto justify-center"
              >
                <div className="w-[18.32px]">
                  <img
                    src={Vector}
                    alt="Vector Icon"
                    className="w-full h-full"
                  />
                </div>
                Generate Plans
              </button>

              <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="relative z-50"
              >
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center h-full">
                  <Dialog.Panel className="bg-custom-gray w-full h-full overflow-auto shadow-lg relative">
                    <button
                      onClick={() => setIsOpen(false)}
                      className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                    >
                      ✕
                    </button>
                    <CuratingPlanMentor
                      dialogText={dialogText}
                      dialogProfession={dialogProfession}
                    />
                  </Dialog.Panel>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>

      <div>
        <HomeComponet />
      </div>
    </div>
  );
};

export default Mentor;
