import React, { useState } from "react";
import AnimatedText from "../components/AnimatedText";
import Vector from "../assests/icons/Vector.svg";
import PromptSvg from "../assests/icons/Frame2.svg";
import CuratingPlan from "../components/CuratingPlan";
import { Dialog } from "@headlessui/react";
import HomeComponet from "../components/homecomponent";
import SEO from '../components/Seo';
import Hero from '../assests/images/Hero.jpg'




const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputPrompt, setInputPrompt] = useState("");
  const [dialogText, setDialogText] = useState("");

  const toggleCuratingPlan = () => {
    setDialogText(inputPrompt);
    setIsOpen(true);
  };
  const handleSubmission = () => {
    toggleCuratingPlan();
    console.log("Submitted:", inputPrompt);
    // Add any additional logic like API calls here.
  };

  return (
    <div className="home-page">
      <SEO 
        title="trackhabit.io - Create Goals"
        description="Set and achieve your goals with our AI-powered tool. Track activities, build personalized objectives, and unlock your full potential. Try it for free!"
        canonical="https://trackhabit.io/"
      />
      <div className="hero-section relative overflow-hidden">
        <div className="w-full px-8 sm:px-16 pt-[120px] pb-[4.5rem] from-[#06030b] via-[#06030b] to-[#06030b] flex-col justify-start items-center gap-[72px] inline-flex relative z-10 hero-container bg-custom-gray">
          <img className="absolute top-0 left-0 w-full h-full object-cover" src={Hero} alt="hero"/>
          <div className="self-stretch flex-col justify-start items-center gap-8 flex z-[99]">
            <h1 className="self-stretch flex-col justify-center items-center flex">
              <div className="self-stretch text-center text-white font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px]">
                Discover the
              </div>
              <div className="inline-flex flex-col gap-2 sm:flex-row sm:gap-4 md:flex-row md:gap-4 self-stretch justify-center items-center animated-text-container">
                <div className="text-[#302d38] font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px] text-center sm:text-left sm:justify-start sm:items-start text-era text-gradient">
                  New Era of
                </div>
                <div className="flex-col justify-start items-center inline-flex animated-text">
                  <div className="w-full justify-start items-center inline-flex">
                    <div className="flex-col justify-center items-start inline-flex ">
                      <AnimatedText />
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch text-center text-white font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px]">
                With Powerful AI
              </div>
            </h1>
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <p className="grow shrink basis-0 text-center text-[#aeadb2] text-2xl font-light font-['Outfit'] max-w-[600px]">
                Gain personalized recommendations and real-time analytics to
                optimize your daily habits.
              </p>
            </div>
          </div>
          <div className="Prompt">
            <div className="w-full max-w-[1076px] self-stretch px-6 pt-4 pb-8 bg-[#3f3c45]/30 rounded-[40px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-6 flex sm:px-8">
              <div className="self-stretch text-[#aeadb2] text-lg font-normal font-['Outfit']">
                Define your goal in a short one-to-two-sentence statement
              </div>
              <div className="self-stretch h-auto sm:h-20 pl-4 pr-4 sm:pl-6 sm:pr-2 py-2 bg-[#3f3c45]/30 rounded-[20px] sm:rounded-[999px] border-2 border-[#302d38] flex flex-col justify-start sm:justify-between items-center sm:inline-flex sm:flex-row">
                <div className="flex items-center bg-transparent pr-4 pl-0 sm:px-4 pb-10  sm:py-4 w-full sm:w-[69%] lg:w-[80%]">
                  {/* SVG Icon as Image */}
                  <img
                    src={PromptSvg}
                    alt="Icon"
                    className="w-5 sm:w-8 h-5 sm:h-8 text-[#aeadb2] mr-3"
                  />

                  {/* Input Field */}
                  <input
                    type="text"
                    value={inputPrompt}
                    onChange={(e) => setInputPrompt(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && inputPrompt.trim() !== "") {
                        handleSubmission();
                      }
                    }}
                    placeholder="e.g. Learn to play guitar in 4 months"
                    className="bg-transparent outline-none text-[#aeadb2] text-base font-medium font-['Outfit'] flex-grow"
                  />
                </div>
                <button
                  onClick={() => {
                    if (inputPrompt.trim() === "") {
                      alert("Please enter your Goal");
                    } else {
                      handleSubmission();
                    }
                  }}
                  className="hidden md:flex px-5 py-2 rounded-full border border-gradient-generate rounded-[999px] text-white text-sm font-['Outfit'] gap-2 goalSubmitted border border-[#50D049]"
                >
                  <div className="w-[18.32px]">
                    <img
                      src={Vector}
                      alt="Vector Icon"
                      className="w-full h-full"
                    />
                  </div>
                  Generate Plans
                </button>

                {/* Full-Screen Modal */}
                <Dialog
                  open={isOpen}
                  onClose={() => setIsOpen(false)}
                  className="relative z-50"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center h-full">
                    <Dialog.Panel className="bg-custom-gray w-full h-full overflow-auto shadow-lg relative">
                      {/* Close Button */}
                      <button
                        onClick={() => setIsOpen(false)}
                        className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                      >
                        ✕
                      </button>

                      {/* CuratingPlan Component */}
                      <CuratingPlan dialogText={dialogText} />
                    </Dialog.Panel>
                  </div>
                </Dialog>
              </div>
              <button
                onClick={() => {
                  if (inputPrompt.trim() === "") {
                    alert("Please enter your Goal");
                  } else {
                    handleSubmission();
                  }
                }}
                className="w-full border-gradient-generate border border-gradient-generate border-[#50D049] flex justify-center align-center md:hidden px-5 py-2 rounded-full border border-[#4fd049] text-white text-sm font-['Outfit'] gap-2 goalSubmitted"
              >
                <div className="w-[18.32px]">
                  <img
                    src={Vector}
                    alt="Vector Icon"
                    className="w-full h-full"
                  />
                </div>
                Generate Plans
              </button>

              <div className="self-stretch justify-start items-center gap-4 flex flex-wrap ">
                {[
                  "Learn to cook three new recipes",
                  "Practice meditation for 10 minutes daily",
                  "Learn about investing and start a small portfolio",
                  "Explore local hiking trails",
                  "Plan a family movie night each week",
                ].map((label) => (
                  <button
                    key={label}
                    onClick={() => {
                      console.log(label);
                      setDialogText(label);
                      setIsOpen(true);
                    }}
                    className="px-5 py-2 justify-center items-center gap-3 flex text-white text-sm font-normal font-['Outfit'] border-gradient listedGoals text-left"
                  >
                    <span>
                    <div className="w-[18.32px] relative">
                      <img
                        src={Vector}
                        alt="Vector Icon"
                        className="w-full h-full"
                      />
                    </div>
                    {label}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <HomeComponet/>
      </div>
    </div>
  );
};

export default Home;
