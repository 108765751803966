import React, { useState } from "react";
import HomeComponet from "../components/homecomponent";
import AnimatedText from "../components/AnimatedText";
import EmailSection from "../components/EmailSection";
import { Dialog } from "@headlessui/react";
import SEO from '../components/Seo';
import Hero from '../assests/images/Hero.jpg'


const SignUpToday = () => {
    const [isOpen, setIsOpen]=useState(false)

    const toggleCuratingPlan = () => {
        setIsOpen(true);
    };
    const handleSubmission = () => {
        toggleCuratingPlan();
      };


  return (
    <div className="signup-page">
      <SEO 
        title="trackhabit.io - Track your habits"
        description="Set and achieve your goals with our AI-powered tool. Track activities, build personalized objectives, and start achieving your dreams today. Try it for free!"
        canonical="https://trackhabit.io/"
      />
      <div className="hero-section relative overflow-hidden">
        <div className="w-full px-8 sm:px-16 pt-[120px] pb-[4.5rem] from-[#06030b] via-[#06030b] to-[#06030b] flex-col justify-start items-center gap-[72px] inline-flex relative z-10 hero-container bg-custom-gray">
        <img className="absolute top-0 left-0 w-full h-full object-cover" src={Hero} alt="hero"/>
          <div className="self-stretch flex-col justify-start items-center gap-8 flex z-[99]">
          <h1 className="self-stretch flex-col justify-center items-center flex">
              <div className="self-stretch text-center text-white font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px]">
                Discover the
              </div>
              <div className="inline-flex flex-col gap-2 sm:flex-row sm:gap-4 md:flex-row md:gap-4 self-stretch justify-center items-center animated-text-container">
                <div className="text-[#302d38] font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px] text-center sm:text-left sm:justify-start sm:items-start text-era text-gradient">
                  New Era of
                </div>
                <div className="flex-col justify-start items-center inline-flex animated-text">
                  <div className="w-full justify-start items-center inline-flex">
                    <div className="flex-col justify-center items-start inline-flex ">
                      <AnimatedText />
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch text-center text-white font-light font-['Outfit'] text-[45px] sm:text-[56px] leading-[50px] sm:leading-[64px]">
                With Powerful AI
              </div>
            </h1>
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <p className="grow shrink basis-0 text-center text-[#aeadb2] text-2xl font-light font-['Outfit'] max-w-[600px]">
                Gain personalized recommendations and real-time analytics to
                optimize your daily habits.
              </p>
            </div>
          </div>

          <div className="w-full max-w-[1076px] mx-auto self-stretch px-6 pt-4 pb-8  rounded-[40px]  flex-col justify-center items-center gap-6 flex sm:px-8">

            {/* Sign up today and Dialog */}
            <div className="self-stretch flex-col justify-center items-center gap-2 flex">
              <button
                onClick={handleSubmission}
                className="flex px-7 py-2 rounded-full border border-gradient text-white text-sm font-['Outfit'] gap-2 signUpToday border border-[#50D049]"
              >
                <span>
                  Sign Up Today
                </span>
              </button>

              <Dialog
                open={isOpen}
                onClose={() => setIsOpen(false)}
                className="relative z-50"
              >
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center h-full">
                  <Dialog.Panel className="bg-custom-gray w-full h-full overflow-auto shadow-lg relative">
                    <button
                      onClick={() => setIsOpen(false)}
                      className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                    >
                      ✕
                    </button>
                    <EmailSection/>
                  </Dialog.Panel>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>

      <div>
        <HomeComponet />
      </div>
    </div>
  );
};

export default SignUpToday;
