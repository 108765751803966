import React from "react";
import SEO from "../components/Seo";

const PrivacyPolicy = () => {
  return (
    <div className="max-w-[1230px] mx-auto pt-[65px] sm:pt-[40px] pb-[96px] px-[32px]">

            <SEO
        title="trackhabit.io - Privacy"
        description="Read the privacy policies for TrackHabit, your habit tracker application. Make sure to understand our Privacy statement."
        canonical="https://trackhabit.io/"
      />
      <div className="flex flex-col justify-center items-center gap-8 ">
        <div className="self-stretch flex flex-col justify-start items-start gap-6 border-b border-[#65636A] pb-[32px]">
          <div className="self-stretch flex flex-col justify-start items-start gap-3">
            <h1 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
              Privacy Policy
            </h1>
            <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
              Last updated: 3 December 2024
            </p>
          </div>
          <div className="self-stretch">
            <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
              Protecting your private information is our priority. This Privacy
              Policy applies to{" "}
            </span>
            <span className="text-[#4fd049] text-lg font-light font-['Outfit'] underline">
              https://trackhabit.io
            </span>
            <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
              {" "}
              and TrackHabit, governing data collection and usage. For the
              purposes of this Privacy Policy, unless otherwise noted, all
              references to TrackHabit include{" "}
            </span>
            <span className="text-[#4fd049] text-lg font-light font-['Outfit'] underline">
              https://trackhabit.io
            </span>
            <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
              .
            </span>
            <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
              {" "}
              The TrackHabit website is a habit tracking and personal
              productivity platform. By using the TrackHabit website, you
              consent to the data practices described in this statement.
            </span>
          </div>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3 border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            1. Introduction
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            Welcome to TrackHabit.io! Your privacy is important to us, and this
            Privacy Policy explains how we collect, use, and protect your
            personal information when you use our website and services. By
            accessing or using TrackHabit.io, you agree to this Privacy Policy.
          </p>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3 border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            2. Information We Collect
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            We may collect the following types of information:
          </p>
          <div className="self-stretch">
            <ul className="list-disc pl-8 pt-[12px] text-[#fff]">
              <li>
                <span className="text-[#aeadb2] text-lg font-semibold font-['Outfit']">
                  Personal Information:
                </span>
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  {" "}
                  Name, email address, and any other details you provide when
                  signing up or interacting with our services.
                  <br />
                </span>
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  <br />
                </span>
              </li>
              <li>
                <span className="text-[#aeadb2] text-lg font-semibold font-['Outfit']">
                  Usage Data:
                </span>
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  {" "}
                  Information about how you interact with our website, such as
                  IP address, browser type, pages visited, and time spent on the
                  site.
                  <br />
                </span>
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  <br />
                </span>
              </li>
              <li>
                <span className="text-[#aeadb2] text-lg font-semibold font-['Outfit']">
                  Cookies and Tracking Technologies:
                </span>
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  {" "}
                  We use cookies to enhance user experience, analyze site
                  traffic, and serve relevant content.
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3 border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            3. How We Use Your Information
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            Your information may be used for:
          </p>
          <ul className="list-disc pl-8 pt-[12px] text-[#fff]">
            <div className="self-stretch">
              <li>
                {" "}
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  Providing and improving our services.
                  <br />
                </span>
              </li>
              <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                <br />
              </span>
              <li>
                {" "}
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  Personalizing your user experience.
                  <br />
                </span>
              </li>
              <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                <br />
              </span>
              <li>
                {" "}
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  Communicating with you regarding updates, offers, and support.
                  <br />
                </span>
              </li>
              <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                <br />
              </span>
              <li>
                {" "}
                <span className="text-[#aeadb2] text-lg font-light font-['Outfit']">
                  Ensuring compliance with legal obligations and protecting
                  against misuse.
                </span>
              </li>
            </div>
          </ul>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3 border-b border-[#65636A] pb-[32px]">
          <h3 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            4. Sharing Your Information
          </h3>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            We do not sell, rent, or share your personal information with third
            parties, except:
          </p>

          <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            <ul className="list-disc pl-8 pt-[12px] text-[#fff]">
              <li>When required by law or to comply with legal processes.  </li>
              <br />
              <li>
                {" "}
                With trusted service providers who assist in our operations,
                under strict confidentiality agreements.
              </li>
            </ul>
          </div>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3 border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            5. Your Rights
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            You have the following rights:
          </p>
          <ul className="list-disc pl-8 pt-[12px] text-[#fff]">
            <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
              <li> Access and update your personal information.</li>
              <br />
              <li> Request the deletion of your personal information.</li>
              <br />
              <li> Opt out of marketing communications.</li>
            </div>
          </ul>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3 border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            6. Data Security
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            We implement reasonable security measures to protect your data
            against unauthorized access, disclosure, or destruction. However, no
            data transmission over the internet can be guaranteed to be 100%
            secure.
          </p>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3 border-b border-[#65636A] pb-[32px]">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            7. Changes to This Privacy Policy
          </h2>
          <ul className="list-disc pl-8 pt-[12px] text-[#fff]">
            <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
              <li>
                {" "}
                We may update this Privacy Policy from time to time. Any changes
              </li>
              <li>
                {" "}
                will be posted on this page with an updated revision date.
              </li>
            </div>
          </ul>
        </div>
        <div className="self-stretch flex flex-col justify-start items-start gap-3">
          <h2 className="self-stretch text-white text-[28px] font-light font-['Outfit']">
            8. Contact Us
          </h2>
          <p className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
            If you have any questions or concerns about this Privacy Policy,
            <br />
            please contact us at support@trackhabit.io.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
