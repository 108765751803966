import React from "react";
import SEO from "../components/Seo";
import ContactImage from "../assests/images/ContactIllustration.png";
import EmailLogo from "../assests/images/EmailLogo.png";

const ContactUS = () => {
  return (
    <div className="contact-page">
      <SEO
        title="trackhabit.io - Contact-Us"
        description="Contact TrackHabit to learn more about how to track your habits, for support, or to give us any feedback to make the app even better!"
        canonical="https://trackhabit.io/"
      />
      <div>
        <div className="mx-auto w-full px-8 sm:px-16 pt-[65px] sm:pt-[40px] pb-24 flex-col justify-start items-center gap-16 inline-flex">
          <div className="flex flex-col gap-4">
            <h1 className="self-stretch text-center text-white text-4xl font-light font-['Outfit']">
              Contact US
            </h1>
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <p className="grow shrink basis-0 text-center text-[#aeadb2] text-2xl font-light font-['Outfit'] max-w-[800px]">
                Trackhabit.io is committed to making your user experience
                pleasant and productive while using this site. Should you have
                any additional questions, comments, or compliments,please reach
                us via email.
              </p>
            </div>
          </div>
          <div className="self-stretch px-[122px] justify-center items-start gap-4 inline-flex">
            <div className="justify-start items-center gap-6 flex flex-col lg:flex-row">
              <div className="w-full lg:w-[48%] self-stretch p-4 sm:p-6 bg-[#3f3c45]/30 rounded-[20px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-start items-start gap-8 inline-flex">
                <h2 className="self-stretch text-white text-2xl font-light font-['Outfit']">
                  Contact Us
                </h2>
                <div className="flex-col justify-start items-start gap-6 flex">
                  <div className="w-full justify-start items-center gap-4 inline-flex">
                    <div className="w-14 h-14 px-4 pt-[15.50px] pb-[16.50px] bg-[#302d38] rounded-[999px] justify-center items-center flex">
                      <img
                        src={EmailLogo}
                        alt="Email-Logo"
                        className="w-6 h-6 relative flex-col justify-start items-start flex"
                      />
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                      <div className="self-stretch text-white text-xl font-light font-['Outfit']">
                        Email
                      </div>
                      <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">
                        support@trackhabit.io
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-full sm:w-[466px] justify-start items-center gap-4 inline-flex">
            <div className="w-14 h-14 px-4 pt-[15.50px] pb-[16.50px] bg-[#302d38] rounded-[999px] justify-center items-center flex">
              <div className="w-6 h-6 relative flex-col justify-start items-start flex" />
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
              <div className="self-stretch text-white text-xl font-light font-['Outfit']">Phone</div>
              <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">(+91) 987 676 5654</div>
            </div>
          </div> */}
                  {/* <div className="w-full sm:w-[466px] justify-start items-center gap-4 inline-flex">
            <div className="w-14 h-14 px-4 pt-[15.50px] pb-[16.50px] bg-[#302d38] rounded-[999px] justify-center items-center flex">
              <div className="w-6 h-6 relative flex-col justify-start items-start flex" />
            </div>
            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
              <div className="self-stretch text-white text-xl font-light font-['Outfit']">Address</div>
              <div className="self-stretch text-[#aeadb2] text-xl font-light font-['Outfit']">Insert Physical Address</div>
            </div>
          </div> */}
                </div>
              </div>
              <div className="grow shrink basis-0 self-stretch p-6 sm:p-10 bg-[#3f3c45]/30 rounded-[20px] shadow border border-[#302d38] backdrop-blur-[56px] flex-col justify-center items-center gap-8 inline-flex">
                {/* <div className="w-[388px] h-[338.29px] relative">
          <div className="w-[83.24px] h-[37.49px] left-[10.37px] top-[298.08px] absolute">
            <div className="w-[71.86px] h-[37.44px] left-[11.38px] top-0 absolute">
              <div className="w-[57.55px] h-[19.58px] left-[7.68px] top-[11.52px] absolute">
              </div>
            </div>
          </div>
          <div className="w-[198.91px] h-[217.05px] left-[188.66px] top-[120.04px] absolute">
            <div className="w-28 h-[97.29px] left-[86.88px] top-[119.76px] absolute">
            </div>
            <div className="w-[169.42px] h-[216.09px] left-0 top-0 absolute">
              <div className="w-[164.57px] h-[122.02px] left-0 top-[94.07px] absolute">
                <div className="w-[145.38px] h-[122.02px] left-[19.19px] top-[-0px] absolute">
                  <div className="w-[58.50px] h-[116.98px] left-0 top-[4.89px] absolute">
                  </div>
                </div>
                <div className="w-[38.99px] h-[24.31px] left-0 top-[97.07px] absolute">
                </div>
                <div className="w-[20.36px] h-[40.37px] left-[48.29px] top-[44.72px] absolute">
                </div>
              </div>
              <div className="w-[69.49px] h-[96.21px] left-[56.01px] top-[70.09px] absolute">
                <div className="w-[29.49px] h-[95.61px] left-[24.11px] top-0 absolute">
                </div>
              </div>
              <div className="w-[86.43px] h-[89.85px] left-[49.15px] top-[-0px] absolute">
                <div className="w-[65.97px] h-[77.07px] left-[8.37px] top-[12.78px] absolute">
                </div>
              </div>
              <div className="w-[29.01px] h-[86.65px] left-[96.76px] top-[3.66px] absolute">
              </div>
              <div className="w-[65.07px] h-[136.54px] left-[104.35px] top-[66.69px] absolute">
                <div className="w-[24.81px] h-[53.49px] left-0 top-0 absolute">
                </div>
              </div>
            </div>
          </div>
          <div className="w-[110.28px] h-[50.80px] left-[115.28px] top-[141.30px] absolute">
            <div className="w-[75.90px] h-[48.66px] left-[34.38px] top-0 absolute">
              <div className="w-[32.85px] h-[6.06px] left-[21.52px] top-[14.38px] absolute">
              </div>
            </div>
          </div>
          <div className="w-[185.02px] h-[106.99px] left-[63.70px] top-[228.92px] absolute">
          </div>
          <div className="w-[241.61px] h-[71.75px] left-[79.19px] top-[38.41px] absolute">
            <div className="w-[66.69px] h-[43.20px] left-[174.91px] top-[13.92px] absolute">
            </div>
            <div className="w-[71.75px] h-[71.75px] left-[-0px] top-[-0px] absolute">
              <div className="w-[0px] h-[44.60px] left-[35.78px] top-[13.64px] absolute">
              </div>
              <div className="w-[31.53px] h-[31.53px] left-[20.01px] top-[20.17px] absolute">
              </div>
              <div className="w-[31.53px] h-[31.53px] left-[20.01px] top-[20.17px] absolute">
              </div>
            </div>
            <div className="w-[68.53px] h-[43.77px] left-[84.51px] top-[13.92px] absolute">
              <div className="w-[68.53px] h-[43.77px] left-[-0px] top-[-0px] absolute">
              </div>
            </div>
          </div>
        </div> */}
                <img src={ContactImage} alt="contact-page-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUS;
